import React from 'react'
import fetch from 'cross-fetch'
import Recaptcha from 'react-google-recaptcha'
import { Check } from 'react-feather'

import { logEvent } from '../utils/analytics'

import Button from './button'


const recaptchaRef = React.createRef();
class EventForm extends React.Component {
    
    constructor(props) {
      super(props)
  
      this.state = {
        recaptcha: false,
        submitted: false,
        email: ''
      }
  
      this.handleCaptcha = this.handleCaptcha.bind(this)
      this.handleChange = this.handleChange.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this)
    }
  
    
  
    encode(data) {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
    }
  
    handleCaptcha(value) {
      if (value) {
        this.setState({ ...this.state, recaptcha: true })
      }
    }
    
    toggleButton(e) {
      const target = e.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
        [name]: value
      });
      
      console.log('clicked')
    }
    handleChange(e) {
      this.setState({ ...this.state, [e.target.name]: e.target.value })
      
      this.toggleButton(e)
      console.log(this.state)
      
    }
  
  
    handleSubmit(event) {
      event.preventDefault()
  
      
      const recaptchaValue = recaptchaRef.current.getValue();
      const form = event.target
      console.log(this.state)
      if (this.state.recaptcha) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': form.getAttribute('name'),
            'g-recaptcha-response': recaptchaValue,
            ...this.state
          })
        })
          .then(() => {
            const object = {
              category: 'Event',
              action: 'Submitted Event Form'
            }
            logEvent(object)
            this.setState({
              submitted: true,
              email: ''
            })
          })
          .catch(error => window.alert(error))
      }
    }
  
    
    render() {
      const { submitted } = this.state
  
     
     
  
      return (
        <div className="inline-block relative w-full">
          <div className="block relative center m-auto">
            {submitted ? (
              <div className="inline-block relative w-full bg-green-900 p-12">
              <Check
                className="w-12 h-12 bg-green-500 rounded-full mb-8 mx-auto p-2"
                color="white"
              />
              <strong className="inline-block relative w-full text-white mb-4">
                Thank you for joining us!
              </strong>
              <p className="inline-block relative w-full text-sm text-gray-400">
                We'll get back to you shortly.
              </p>
            </div>
          ) : (
            <div className="flex flex-col w-full h-auto  pb-10 lg:pb-0 text-left ">
              
         
            <form 
                data-netlify-honeypot="bot-field"
                data-netlify="true"
                data-netlify-recaptcha="true"
                className="inline-block relative w-full"
                onSubmit={this.handleSubmit}
                method="post"
                name="Events"
                action="/"
              >
                {/* HIDDEN FOR NETLIFY */}
              <input type="hidden" name="form-name" value="Events" />
              {/* FORM */}
              <input
                      placeholder="Sign up to join us"
                      className='inline-block relative w-full lg:w-3/4 my-6 px-4 py-2 bg-brand-primary border-4 text-white placeholder-white'
                      type="email"
                      name="email"
                      value={this.state.value}
                      onChange={this.handleChange}
                      required
                />
               
        
           
                    
           
                {/* RECAPTCHA */}
                <div>
                  <Recaptcha
                    onChange={this.handleCaptcha}
                    sitekey="6Lc1X3ggAAAAAPxEcJkwFiEwWkr9gO81fIYnURo_"
                    ref={recaptchaRef}
                    id="recaptcha-google"
                  />
                </div>
                <Button primary outlined margin={'mt-6'} width={'w-40'} text="Join Us" type="submit"/>
              </form>
              </div>
            )}
           
            </div>
        </div>
      )
    }
  }
  
  export default EventForm
  