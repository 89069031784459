import React, {useState} from 'react'
import { graphql } from 'gatsby'

import fetch from 'cross-fetch'

import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'

import Container from '../components/container'
import SEO from '../components/seo'

import Layout from '../containers/layout'
import BlockContent from '../components/block-content'

import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import Button from "../components/button";
import { Check } from 'react-feather'

import EventForm from '../components/eventsForm'


export const query = graphql`
  query EventsPageQuery {
    page: sanityEvents(_id: { regex: "/(drafts.|)events/" }) {
      id
      title
      image {
        asset {
          _id
        }
      }
      _rawBody
      date
      location
    }
    
  }
`

const EventsPage = props => {

 

  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  
  // console.log(body)
  if (!page) {
    throw new Error(
      'Missing "Events" page data. Open the studio at http://localhost:3333 and add "Events" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO  />
      <Container >

      <div className="inline-block relative pt-20 w-full text-left md:text-center">
      <div className="flex h-full lg:h-screen flex-col-reverse lg:flex-row justify-center items-center relative w-full">
        <div className="flex flex-col w-full lg:w-1/2 h-auto px-10 pb-10 lg:pb-0 text-left lg:px-20">
          <h2 className="w-full  text-6xl font-bold mb-8 leading-tight text-left">{page.title}</h2>
              <h4 className="font-semibold text-xl">{page.location}</h4>
              <h4 className="font-semibold text-xl">{page.date}</h4>
              <BlockContent blocks={page._rawBody || []} />
              <EventForm page={page}/>
       
          
            
    
           
        </div>
        <img
            className={`w-full lg:w-1/2 h-56 lg:h-full fit rounded-sm my-8`}
            src={imageUrlFor(buildImageObj(page.image)).url()}
        />
        </div>
        </div>
      </Container>
    </Layout>
  )
}

export default EventsPage